import React, {useRef, useState} from "react";
import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, CardGroup, ListGroup, ListGroupItem, Form, FormControl, Button} from 'react-bootstrap'
import axios from  'axios'
const initialState  = {
  name: '',
  phonenumber: '',
  email: '',
  message: ''
}

const resetState = {
  name: '',
  phonenumber: '',
  email: '',
  message: ''
}

const fieldMatch = {
  name: 'Full Name',
  phonenumber: 'Phone Number',
  email: 'Email',
  message: 'Inquiry Description'
}

function ContactPage(props){

  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState('')
  
  console.log(state)

  const handleSubmit = e => {
    e.preventDefault()

    for(let key in state){
      if(state[key] === ''){
        
          console.log(fieldMatch[key])  
          let match = fieldMatch[key]
            setErrors(`You must provide the  ${match} `)
          
        
        
        console.log(errors)
        return
      }
    
    }
    setErrors('')
 

    sendContactForm()
   }

   

   const handleInput = e => { 
    // if(e.keyCode === 13){
    //   handleSubmit(e)
    // }
    
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
    setState(prev => ({...prev, [inputName]: value}))
  }

  function sendContactForm(){
    axios({
      method: "POST",
      url:"https://bess.bz/sendcontact.php ",
      data:  state
    }).then((response)=>{ 
      if (response.data.status === 'success') {
        alert("Message Sent.");
        setState(resetState)
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  return(
    <>
 
 
  <Card.Title className=" text-center engineeringHeader white-text">Contact Us</Card.Title>


<Row className="container-fluid center-block  ">

<div className="col-md-3"></div>
<div className="col-md-6 "> 
{ errors && ( <p>{errors}</p>)
}
<Card.Title className=" text-center engineeringHeader white-text">Leave us a message and we'll get back to you ASAP!</Card.Title>
<Form>
  <Form.Group className="mb-3" controlId="formBasicEmail">
  <Form.Label>Full Name</Form.Label>
    <Form.Control type="text" placeholder="Enter Full Name " name="name" value={state.name}  onChange={handleInput} onKeyDown={handleInput} />
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" placeholder="Enter Email"  name="email" value={state.email}  onChange={handleInput} onKeyDown={handleInput}/>
   
    <Form.Label>Phone Number</Form.Label>
    <Form.Control type="text" placeholder="Enter Phone Number" name="phonenumber" value={state.phonenumber}  onChange={handleInput} onKeyDown={handleInput}/>
  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Inquiry Description</Form.Label>

    <FormControl as="textarea" rows={8} aria-label="Inquiry" placeholder="Enter Inquiry " name="message" value={state.message}  onChange={handleInput} onKeyDown={handleInput} />
  </Form.Group>
  <div className="text-center"><Button className="engineeringHeader bottomM"  onClick={e => handleSubmit(e)}>
    Submit
  </Button>
  </div>
</Form>
    
</div>
<div className="col-md-3"></div>
  </Row>
 
    </>
  )
}

export default ContactPage;
