import React from 'react'
import {Nav, Navbar, NavbarBrand, Container, Button, Row, Col} from 'react-bootstrap'

export const Footer = props => {

  return (
    <>


   <footer>  
   
    <div className="container ">
    
<Row>
    <div className="col-md-4 ">
    <a  className="footerLightLink white-text" href="/civil">Civil Engineering</a>
    <br></br>
    <a  className="footerLightLink white-text" href="/mechanical">Mechanical Engineering</a>
    <br></br>
  
    <a  className="footerLightLink white-text" href="/computer">Computer Systems Engineering</a>
    <br></br>
    <a  className="footerLightLink white-text" href="/electrical">Electrical Engineering</a>
    </div>
    
    <div className="col-md-4 text-center">
    <a  className="footerLightLink white-text" href="/">
   <img 
    src={"./" + 'bess.png'}
    width='128px'
    height='128px'
    /> 
    </a>
    </div>
    
    <div className="col-md-4 ">
 <a className="white-text footerLink articlePad" href={"callto:" + "+5016746664"}> <i className="fas fa-phone-alt"></i> (+501) 674-6664</a>
<br></br>
      <a className="white-text footerLink articlePad" href={"mailto:" + "engineering@bess.bz"}> <i className="fas fa-envelope"></i> engineering@bess.bz</a>
    </div>
    
  </Row>
    <hr className="hrWhite"></hr>
    <Container className="text-center">
              <span className="text-center"> Copyright 2022 BESS</span> 
              </Container>
    </div>
            
        </footer>




    </>
  )

}
