import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import {HomeContainer} from './HomeContainer'
import HomePage from './home'
import AboutPage from './about'
import CivilPage from './civil'
import MechanicalPage from './mechanical'
import ElectricalPage from './electrical'
import ComputerPage from './computer'
import SuppliesPage from './supplies'
import ContactPage from './contact'
import {NavBar} from './navbar.js'
import {Footer} from './footer.js'
import './style.css'
import './diamond.css'

import 'bootstrap/dist/css/bootstrap.min.css';

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}
function AppController() {
  return (
    <div className="AppController whiteBG">
    <NavBar />
    
      <Switch >
        <HomeContainer exact path="/" component={HomePage}/>
        
        <HomeContainer exact path="/civil" component={CivilPage}/>
        <HomeContainer exact path="/mechanical" component={MechanicalPage}/>
        <HomeContainer exact path="/computer" component={ComputerPage}/>
        <HomeContainer exact path="/electrical" component={ElectricalPage}/>
        <HomeContainer exact path="/supplies" component={SuppliesPage}/>
        <HomeContainer exact path="/contact" component={ContactPage}/>
        <HomeContainer exact path="/about" component={AboutPage}/>
        
        <Route path="*" component={()=> "404 NOT FOUND"}/>
    </Switch>
    <Footer />
    </div>

  )
}

const load = () =>{
  ReactDOM.render(
    <BrowserRouter>
      <AppController />
    </BrowserRouter>
  )
}

const rootElement = document.getElementById("root")
  ReactDOM.render(
    <BrowserRouter>
      <AppController />
    </BrowserRouter>,
    rootElement
  )
