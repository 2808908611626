import React from 'react'
import {Route, Redirect } from 'react-router-dom'
import {NavBar} from './navbar.js'
import {Footer} from './footer.js'

export const HomeContainer = ({
  component: Component, 
  ...rest
}) => {
  return (
    <Route 
      {...rest}
    render={props => {
        return (
          <div>
          
            <Component {...props} />
          
          </div>
        )
    }}
    />
  )
}
