import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, CardGroup, ListGroup, ListGroupItem, CardDeck} from 'react-bootstrap'

function SuppliesPage(props){

  return(
    <>
 
 <Card.Title className=" text-center engineeringHeader white-text">Supplies</Card.Title>

 


<Row className="container-fluid center-block">





    
    </Row>
  
 
    </>
  )
}

export default SuppliesPage;
