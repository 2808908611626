import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, CardGroup, ListGroup, ListGroupItem, CardDeck} from 'react-bootstrap'

function ElectricalPage(props){

  return(
    <>

    <Card.Title className=" text-center engineeringHeader white-text">Electrical Engineering</Card.Title>
 


<Row className="container-fluid center-block">
<div className="col-md-2"> </div>
<div className="col-md-8">
<CardDeck className="bottomP">
  <Card  className="boxShadow">


    <Card.Img variant="top" src={"./" + "electrical1.png"}/>
    <Card.Header className="headerColor white-text text-center"><Card.Title>Electrical Installation & Procedures </Card.Title></Card.Header>
    <Card.Body>
      
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Residential & Commercial Electrical Wiring Design, Technical Drawings and PUC Approvals</ListGroupItem>
    <ListGroupItem>Electrical Installations</ListGroupItem>
    <ListGroupItem>Insulation Resistance Testing of Motors</ListGroupItem>
    <ListGroupItem>Motor Installation and Wiring</ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>

 
  <Card.Body>
    
  </Card.Body>
  
  </Card>


  <Card  className="boxShadow">
    <Card.Img variant="top" src={"./" + "electrical2.png"} />
    <Card.Header className="headerColor white-text text-center"><Card.Title>Electrical Simulations & Designs</Card.Title></Card.Header>
    <Card.Body>
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Power Flow Simulation and Analysis</ListGroupItem>
    <ListGroupItem>High Voltage Systems Sizing and Design</ListGroupItem>
    <ListGroupItem>Solar Panel System Design</ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>
  
  <Card.Body>
   
   </Card.Body>
   
  </Card>



</CardDeck>
    </div>

    <div className="col-md-2"> </div>
    
    </Row>
  
 
    </>
  )
}

export default ElectricalPage;
